import book1 from "./../../image/other/book1.jpg";
import book2 from "./../../image/other/book2.jpg";
import book3 from "./../../image/other/book3.jpg";
import book4 from "./../../image/other/book4.jpg";
import book5 from "./../../image/other/book5.jpg";

 //Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa
import bookdata1 from "./../../book/paper/Yonas_Deressa.pdf";
import bookdata2 from "./../../book/paper/RuneBacklund_Gudina.pdf";
import bookdata3 from "./../../book/paper/TseganeshAyele_Gudina.pdf";
import bookdata4 from "./../../book/paper/TarressaQannoo_Gudina.pdf";


export let _paperdata = [
    
    //Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa
    {
        author: "Gudina Tumsa",
        image: book1,
        uploadDate: "2020-01-04",
        book: bookdata1,
        bookName: "Yonas Deressa",
        popular: 2.8,
        category : "Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa"
    },
    {
        author: "Gudina Tumsa",
        image: book2,
        uploadDate: "2020-03-01",
        book: bookdata2,
        bookName: "RuneBacklund Gudina",
        popular: 1.5,
        category : "Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa"
    },
    {
        author: "Lensa Gudina",
        image: book3,
        uploadDate: "2020-06-01",
        book: bookdata3,
        bookName: "TseganeshAyele Gudina",
        popular: 4.5,
         category : "Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa"
    },
    {
        author: "Lensa Gudina",
        image: book4,
        uploadDate : "2020-04-01",
        book: bookdata4,
        bookName: "TarressaQannoo_Gudina",
        popular: 3.8,
         category : "Gudina Tumsa's Short Biography and different report papers by Gudina Tumsa"
    },
     
]


