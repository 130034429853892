export const home = () => {
    return {
        type : "HOME"
    }
}

export const intro = () => { 
    return {
        type : "INTRO"
    }
}


export const _idiology = () => {
     return {
        type : "Ideology"
    }
 }
export const relationtoothers = () => { 
     return {
        type : "RelationToOthers"
    }
 }
export const papers = () => { 
     return {
        type : "Papers"
    }
 }
export const archive = () => {  
     return {
        type : "Archive"
    }
}
export const gallery = () => {  
   
     return {
        type : "Gallery"
    }
}
//admin

export const adminlogin = () => { 
    return {
        type : "AdminLogin"
    }
}


export const adminHome = () => { 
    return {
        type : "AdminHome"
    }
}


export const adminArchive = () => { 
    return {
        type : "AdminArchive"
    }
}

export const adminBlog = () => { 
    return {
        type : "AdminBlog"
    }
}

export const adminPaper = () => { 
    return {
        type : "AdminPaper"
    }
}



