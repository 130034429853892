

export const setUser = () => {  
   
     return {
        type : "Admin"
    }
}


export const setVisitor = () => {
    return {
        type : "Visitor"
    }
}
